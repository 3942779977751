export enum TeamSessionStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  COMPLETED = 'Completed',
  ARCHIVED = 'Archived',
}

export interface TeamSession {
  id: string;
  label: string;
  status: TeamSessionStatus;
  startDate?: Date;
  completedDate?: Date;
  archived: boolean;
  archivedDate?: Date;
}
